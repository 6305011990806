import React, { Component } from "react";
import Swal from 'sweetalert2';
import { Modal } from "reactstrap";
import SmallCreditCard from "../SmallCreditCard";
import PricingOptions from "../PricingOptions";
import AddCard from "../../pages/Card-management/AddCard";
import addCardIcon from "../../assets/images/icon-new-card.png";
import unselectedCicle from "../../assets/images/unselectedCicle.png";
import circleOrange from "../../assets/images/circleOrange.png";
import IconInformation from "../../assets/images/IconInformation.png";
import IconClock from "../../assets/images/IconClock.png";
import imageIcon from "../../assets/images/ticket-notification.png";
import iconClockOrange from "../../assets/images/icon-clock.png";
import { handleErrors } from '../../Utils/handleError';
import Api from "../../Utils/API";
import { getCookie, setCookie, minTommss, pluralize } from '../../Utils/helper';
import Loading from "../Loading";

let userDetails = getCookie("userDetails");
let loginUser = {};
if (userDetails) { loginUser = JSON.parse(userDetails); }

class CreditBalance extends Component {
    constructor() {
        super();
        this.state = {
            isSubmit: false,
            isOpen: false,
            loadingPlan: true,
            loadingCard: true,
            isApply: false,
            isCB: false,
            addCard: false,
            company_id: loginUser.company ? loginUser.company.id : '',
            list: [],
            crecardList: [],
            standardPlans: [],
            standardList: [],
            monthlyPlans: [],
            monthlyList: [],
            largestandardPlans: [],
            largestandardList: [],
            largemonthlyPlans: [],
            largemonthlyList: [],
            allPlans: [],
            card_id: '',
            plan_id: '',
            activeTab: 1,
            planSlected: '',
            hours: 0,
            creditBalance: '0:00',
            taskBalance: '0:00',
            planType: '',
            noHours: '--',
            total: 0,
            subtotal: 0,
            applyCode: '',
            discount: 0,
            discount_percentage: 0,
            codeIsApplied: false,
        };
        this.selectPayment = this.selectPayment.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getList();
        this.getOptions();
        this.getCredit();
    }

    getList() {
        Api.getCard(this.state.company_id).then(result => {
            let crecardList = result.data;
            for (let index = 0; index < crecardList.length; index++) {
                crecardList[index]['check'] = false;
            }
            setTimeout(() => {
                this.setState({ list: crecardList, crecardList: crecardList, loadingPlan: false });
            }, 1000);
        }).catch(error => {
            // handleErrors();
        });
    }

    getOptions() {
        Api.getOptions().then(result => {
            let list = result.data;
            let standardPlans = [];
            let monthlyPlans = [];
            let largestandardPlans = [];
            let largemonthlyPlans = [];
            for (var i = 0; i < list.length; i++) {
                if (list[i].type === 'standard - hourly') {
                    standardPlans.push(list[i]);
                } else if (list[i].type === 'standard - monthly') {
                    monthlyPlans.push(list[i]);
                } else if (list[i].type === 'large - hourly') {
                    largestandardPlans.push(list[i]);
                } else if (list[i].type === 'large - monthly') {
                    largemonthlyPlans.push(list[i]);
                }
                list[i]['check'] = false;
            }
            this.setState({ allPlans: list, standardPlans: standardPlans, standardList: standardPlans, monthlyPlans: monthlyPlans, monthlyList: monthlyPlans, largestandardPlans: largestandardPlans, largestandardList: largestandardPlans, largemonthlyPlans: largemonthlyPlans, largemonthlyList: largemonthlyPlans, loadingCard: false });

        }).catch(error => {
            handleErrors();
        });
    }

    getCredit() {
        this.setState({ isCB: true });
        Api.getCredit(this.state.company_id).then(result => {
            let cb = result.data.company_credits;
            let tb = result.data.task_balance;
            // setCookie(["creditBalance", cb]);
            // setCookie(["taskBalance", tb]);
            this.setState({ isCB: false, creditBalance: cb, taskBalance: tb })
        }).catch(error => {
            console.log(error);
        });
    }

    selectPayment(e) {
        let crecardList = this.state.list;
        const objIndex = crecardList.findIndex(obj => obj.id === parseInt(e));
        const updatedObj = { ...crecardList[objIndex], check: true };
        const updatedProjects = [
            ...crecardList.slice(0, objIndex),
            updatedObj,
            ...crecardList.slice(objIndex + 1),
        ];
        this.setState({ card_id: e, crecardList: updatedProjects });
    }

    selectOption(e) {
        this.setState({
            standardList: this.state.standardPlans,
            monthlyList: this.state.monthlyPlans,
            largestandardList: this.state.largestandardPlans,
            largemonthlyList: this.state.largemonthlyPlans,
            hours: '',
        });

        if (e.plan === 1 || e.plan === 5) {
            let crecardList = this.state.standardPlans;
            const objIndex = crecardList.findIndex(obj => obj.id === parseInt(e.id));
            const updatedObj = { ...crecardList[objIndex], check: true };
            const updatedProjects = [
                ...crecardList.slice(0, objIndex),
                updatedObj,
                ...crecardList.slice(objIndex + 1),
            ];

            let hours = crecardList[objIndex].name;
            const index = hours.indexOf(' ');
            const isMinutes = hours.indexOf('min') > -1;
            hours = parseInt(hours.substr(0, index));
            if(isMinutes) hours /= 60;

            this.setState({ plan_id: e.id, standardList: updatedProjects, planSlected: 1, hours: hours, noHours: crecardList[objIndex].name, total: crecardList[objIndex].amount, subtotal: crecardList[objIndex].amount, applyCode: '', discount: 0, codeIsApplied: false });
        } else if (e.plan === 2) {
            let crecardList = this.state.monthlyPlans;
            const objIndex = crecardList.findIndex(obj => obj.id === parseInt(e.id));
            const updatedObj = { ...crecardList[objIndex], check: true };
            const updatedProjects = [
                ...crecardList.slice(0, objIndex),
                updatedObj,
                ...crecardList.slice(objIndex + 1),
            ];

            let hours = crecardList[objIndex].name;
            let index = hours.indexOf(' ');
            const isMinutes = hours.indexOf('min') > -1;
            hours = parseInt(hours.substr(0, index));
            if(isMinutes) hours /= 60;

            this.setState({ plan_id: e.id, monthlyList: updatedProjects, planSlected: 2, hours: hours, noHours: crecardList[objIndex].name, total: crecardList[objIndex].amount, subtotal: crecardList[objIndex].amount, applyCode: '', discount: 0, codeIsApplied: false });
        } else if (e.plan === 3) {
            let crecardList = this.state.largestandardPlans;
            const objIndex = crecardList.findIndex(obj => obj.id === parseInt(e.id));
            const updatedObj = { ...crecardList[objIndex], check: true };
            const updatedProjects = [
                ...crecardList.slice(0, objIndex),
                updatedObj,
                ...crecardList.slice(objIndex + 1),
            ];

            let hours = crecardList[objIndex].name;
            let index = hours.indexOf(' ');
            hours = parseInt(hours.substr(0, index));
            // this.setState({ plan_id: e.id, largestandardList: updatedProjects, planSlected: 3, noHours: crecardList[objIndex].name, total: crecardList[objIndex].amount, subtotal: crecardList[objIndex].subtotal });
            this.setState({ plan_id: e.id, largestandardList: updatedProjects, planSlected: 3, hours: hours, noHours: crecardList[objIndex].name, total: crecardList[objIndex].amount, subtotal: crecardList[objIndex].amount, applyCode: '', discount: 0, codeIsApplied: false });
        } else if (e.plan === 4) {
            let crecardList = this.state.largemonthlyPlans;
            const objIndex = crecardList.findIndex(obj => obj.id === parseInt(e.id));
            const updatedObj = { ...crecardList[objIndex], check: true };
            const updatedProjects = [
                ...crecardList.slice(0, objIndex),
                updatedObj,
                ...crecardList.slice(objIndex + 1),
            ];

            let hours = crecardList[objIndex].name;
            let index = hours.indexOf(' ');
            hours = parseInt(hours.substr(0, index));
            // this.setState({ plan_id: e.id, largemonthlyList: updatedProjects, planSlected: 4, noHours: crecardList[objIndex].name, total: crecardList[objIndex].amount, subtotal: crecardList[objIndex].subtotal });
            this.setState({ plan_id: e.id, largemonthlyList: updatedProjects, planSlected: 4, hours: hours, noHours: crecardList[objIndex].name, total: crecardList[objIndex].amount, subtotal: crecardList[objIndex].amount, applyCode: '', discount: 0, codeIsApplied: false });
        }
    }

    handleSubmit() {
        let params = {
            card_id: this.state.card_id,
            plan_id: this.state.plan_id,
            hours: this.state.hours,
            code: this.state.applyCode,
        }

        if (!this.state.card_id) {
            Swal.fire({
                title: 'Error!',
                text: "Please Choose a  Card",
                icon: 'warning',
                confirmButtonText: 'Okay'
            });
        } else if (!this.state.plan_id && !this.state.hours) {
            Swal.fire({
                title: 'Error!',
                text: "Please Choose a Plan",
                icon: 'warning',
                confirmButtonText: 'Okay'
            });
        } else {
            this.setState({ isSubmit: true });
            Api.rechargeBalance(params).then(result => {
                //console.log(result.data);
                this.getCredit();
                this.showTost();
                this.setState({
                    card_id: '',
                    plan_id: '',
                    activeTab: 1,
                    planSlected: '',
                    crecardList: this.state.list,
                    standardList: this.state.standardPlans,
                    monthlyList: this.state.monthlyPlans,
                    largestandardList: this.state.largestandardPlans,
                    largemonthlyList: this.state.largemonthlyPlans,
                    isSubmit: false,
                    isOpen: false
                });
            }).catch(error => {
                this.setState({ isSubmit: false });
                handleErrors();
            });

        }
    }

    changePlan(e) {
        let plan = parseInt(e.target.getAttribute('plan'));
        this.setState({ standardList: this.state.standardPlans, monthlyList: this.state.monthlyPlans });
        this.setState({ planSlected: plan, plan_id: '' });
    }

    showTost() {
        var x = document.getElementById("snackbar-rechange");
        x.className = "show row notification-div";
        setTimeout(() => { this.closeToast(); }, 5000);
    }

    closeToast() {
        var x = document.getElementById("snackbar-rechange");
        x.className = x.className.replace("show", "");
    }

    changeHours(e) {
        let amount = 0;
        let hours = e.target.value.replace(/\D/, '');
        hours = Number(hours);

        let gst = 0;

        if(hours >= 50) {
            amount = hours * 95;
        } else if(hours >= 30) {
            amount = hours * 100;
        } else if(hours >= 20) {
            amount = hours * 105;
        } else if(hours >= 10) {
            amount = hours * 110;
        } else if(hours >= 5) {
            amount = hours * 120;
        } else if(hours >= 3) {
            amount = hours * 130;
        } else if(hours >= 1) {
            amount = hours * 140;
        } else {
            amount = hours * 160;
        }

        gst = (amount * 10) / 110;
        this.setState({ hours: hours, planSlected: 5, noHours: pluralize(hours, 'hour'), total: amount, subtotal: amount, applyCode: '', discount: 0, codeIsApplied: false });
    }

    changeTab(e) {
        let tab = parseInt(e.target.getAttribute('tab'));
        if (tab === 1 || tab === 2) {
            if (this.state.hours) {
                this.setState({
                    plan_id: null,
                    planSlected: '',
                    standardList: this.state.standardPlans,
                    monthlyList: this.state.monthlyPlans,
                    largestandardList: this.state.largestandardPlans,
                    largemonthlyList: this.state.largemonthlyPlans,
                    applyCode: '',
                    codeIsApplied: false
                });
            }
        } else {
            this.setState({
                plan_id: null,
                planSlected: '',
                applyCode: '',
                discount: 0,
                codeIsApplied: false
            });
        }
        this.setState({ activeTab: tab })
    }

    newCard() {
        this.getList();
        this.setState({ addCard: false });
    }

    applyCode() {
        if (!this.state.plan_id && !this.state.hours) {
            Swal.fire({
                title: 'Error!',
                text: "Please Choose a Plan",
                icon: 'error',
                confirmButtonText: 'Close'
            });
        }else if (this.state.applyCode ==='') {
            Swal.fire({
                title: 'Error!',
                text: "Please enter code",
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            this.setState({ isApply: true });
            let allPlans = this.state.allPlans;
            const objIndex = allPlans.findIndex(obj => obj.id === parseInt(this.state.plan_id));
            let selectedPlan = allPlans[objIndex];

            let params = {
                hours: this.state.hours,
                credits: parseFloat(selectedPlan?.credits ?? this.state.hours),
                amount: selectedPlan?.amount,
                code: this.state.applyCode,
                normal_amount: (selectedPlan && selectedPlan.description) ? selectedPlan.description.substr(selectedPlan.description.indexOf("$") + 1) : this.state.hours * 160
            }

            Api.applyCode(params).then(result => {
                const data = result.data;

                this.setState({ isApply: false, total: data.total, subtotal: data.subtotal, discount: data.discount, discount_percentage: data.discount_percentage, codeIsApplied: true });
                Swal.fire({
                    title: 'success',
                    text: "Coupon successfully applied!",
                    icon: 'success',
                    confirmButtonText: 'Close'
                });
            }).catch(error => {  console.log(error.response.data);
                this.setState({ isApply: false });
                Swal.fire({
                    title: 'Error!',
                    text: error.response.data,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            });

        }
    }

    render() {
        let planType = '';
        let planSlected = this.state.planSlected;
        if (planSlected === 1 || planSlected === 2) {
            planType = 'Standard';
        } else if (planSlected === 3 || planSlected === 4) {
            planType = 'Large';
        } else if (planSlected === 5) {
            planType = 'Custom';
        }

        let total = '--';
        let subtotal = '--';
        let gst = '--';
        let discount = `$${parseFloat(this.state.discount).toFixed(2)}`;
        let discount_percentage = parseFloat(this.state.discount_percentage);
        let totalAmount = '--';
        let totalSavings = '--';
        if(planType === 'Custom' || this.state.codeIsApplied) {
            const t = this.state.total;
            total = '$' + (t).toFixed(2);
            totalAmount = total;
            totalSavings = `$${parseFloat((this.state.hours * 160) - t).toFixed(2)}`;
            subtotal = '$' + (this.state.subtotal).toFixed(2);
            gst = `$${parseFloat(t / 11).toFixed(2)}`;
            discount = `$${parseFloat(this.state.discount).toFixed(2)}`;
            discount_percentage = (discount_percentage % 1 === 0) ? discount_percentage : discount_percentage.toFixed(2);
        } else if(this.state.total >= 0 ) {
            let t = this.state.total / 100;
            total = '$' + (t).toFixed(2);
            totalAmount = total;
            totalSavings = `$${parseFloat((this.state.hours * 160) - t).toFixed(2)}`;
            subtotal = `$${parseFloat((this.state.subtotal / 100)).toFixed(2)}`;
            gst = `$${parseFloat(t / 11).toFixed(2)}`;
            discount = `$${parseFloat(this.state.discount).toFixed(2)}`;
            discount_percentage = (discount_percentage % 1 === 0) ? discount_percentage : discount_percentage.toFixed(2);
        }

        return (
            <React.Fragment>
                <div id="snackbar-rechange" onClick={this.closeToast.bind(this)} className="display-none" >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>Success!</h5>
                        <p>You successfully recharged your credit.</p>
                    </div>
                </div>
                <div className="credit-balance" >
                    <img src={iconClockOrange}  />
                    <div className="show-credits">
                        <p className="cbalance">Credit Balance: {this.state.isCB ? <i className="bx bx-loader bx-spin font-size-20 align-middle "></i> : this.state.creditBalance}</p>
                        <p className="tbalance">Including {this.state.taskBalance} for work in progress</p>
                    </div>
                    <button onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }} className="btn btn-primary btn-circle " ><i className="bx bx-plus"></i></button>
                </div>
                <Modal
                    isOpen={this.state.isOpen} 
                    toggle={() => { this.setState({ isOpen: !this.state.isOpen }) }}
                    centered={true}
                    size="lg"
                    className="recharge-balance"
                    style={{ maxWidth: '1200px', width: '80%' }}
                >
                    <div className="modal-header" >
                        <h5 className="modal-title mt-0" style={{marginLeft: 40}}>Recharge Balance</h5>
                        <button
                            type="button"
                            onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{marginLeft: 40}}>
                        <div className="row">
                            <div className="col-lg-7">
                                <h3>Select a Payment Method</h3>
                                <div className="plan-content" >
                                    <Loading show={this.state.loadingCard} title="Fetching Cards..." />
                                    <div className="row">
                                        {this.state.crecardList.map((item, i) => {
                                            return (
                                                <SmallCreditCard
                                                    list={item}
                                                    key={i}
                                                    action={this.selectPayment}
                                                />
                                            );
                                        })}
                                        <div className="col-auto nopadding">
                                            <div className="small-add-card ripple" onClick={event => this.setState({ addCard: true })} >
                                                <img src={addCardIcon} />
                                                <span>Add New Card</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-4 mb-4">Choose a Plan</h3>
                                <div className="tab-plan">
                                    <div className={` ripple ${this.state.activeTab === 1 ? 'active-tab' : ''} `} tab={1} onClick={this.changeTab.bind(this)} >
                                        Standard Plans
                                    </div>
                                    <div className={` ripple ${this.state.activeTab === 2 ? 'active-tab' : ''} `} tab={2} onClick={this.changeTab.bind(this)} >
                                        Large Plans
                                    </div>
                                    <div className={`ripple ${this.state.activeTab === 3 ? 'active-tab' : ''} `} tab={3} onClick={this.changeTab.bind(this)} >
                                        Custom
                                    </div>
                                </div>


                                {
                                    this.state.activeTab === 1 ?
                                        <div className="plan-content" >
                                            <div className="plan-title"> {this.state.planSlected === 1 ? <img src={circleOrange} /> : <img src={unselectedCicle} plan={1} onClick={this.changePlan.bind(this)} />}  Hourly Plan</div>
                                            <div className="row">
                                                {this.state.standardList.map((item, i) => {
                                                    return (
                                                        <PricingOptions
                                                            items={item}
                                                            key={i}
                                                            action={this.selectOption}
                                                            plan={1}
                                                            isSale={true}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        : ''
                                }
                                <Loading show={this.state.loadingPlan} title="Fetching Plans..." />
                                {
                                    this.state.activeTab === 1 ?
                                        <div className="plan-content">
                                            <div className="plan-title"> {this.state.planSlected === 2 ? <img src={circleOrange} /> : <img src={unselectedCicle} plan={2} onClick={this.changePlan.bind(this)} />}  Monthly Plan</div>
                                            <div className="row">
                                                {this.state.monthlyList.map((item, i) => {
                                                    return (
                                                        <PricingOptions
                                                            items={item}
                                                            key={i}
                                                            action={this.selectOption}
                                                            plan={2}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        : ''
                                }
                                <Loading show={this.state.loadingPlan} title="Fetching Plans..." />

                                {/* large plan */}
                                {
                                    this.state.activeTab === 2 ?
                                        <div className="plan-content">
                                            <div className="plan-title"> {this.state.planSlected === 3 ? <img src={circleOrange} /> : <img src={unselectedCicle} plan={3} onClick={this.changePlan.bind(this)} />}  Hourly Plan</div>
                                            <div className="row">
                                                {this.state.largestandardList.map((item, i) => {
                                                    return (
                                                        <PricingOptions
                                                            items={item}
                                                            key={i}
                                                            action={this.selectOption}
                                                            plan={3}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        : ''
                                }

                                {
                                    this.state.activeTab === 2 ?
                                        <div className="plan-content">
                                            <div className="plan-title"> {this.state.planSlected === 4 ? <img src={circleOrange} /> : <img src={unselectedCicle} plan={4} onClick={this.changePlan.bind(this)} />}  Monthly Plan</div>
                                            <div className="row">
                                                {this.state.largemonthlyList.map((item, i) => {
                                                    return (
                                                        <PricingOptions
                                                            items={item}
                                                            key={i}
                                                            action={this.selectOption}
                                                            plan={4}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        : ''
                                }

                                {
                                    this.state.activeTab === 3 ?
                                        <div className="plan-content">
                                            <div className="row mt-3">
                                                <div className="col-6">
                                                    <div className="col-auto">
                                                        <label htmlFor="inputClock">No. of Hours</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text"><img src={IconClock} /></div>
                                                            </div>
                                                            <input pattern="[0-9]*" value={this.state.hours} onChange={this.changeHours.bind(this)} type="text" className="form-control" id="inputClock" placeholder="Enter No. of Hours"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 ">
                                                    <div className="recharge-footer mt-4">
                                                        <div className="text-information"><img src={IconInformation} /> Maximum number of hours is 1000.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }


                                <div className="row " style={{ marginTop: 50, marginBottom: 50 }} >
                                    <div className="col-lg-7 recharge-footer">
                                        {/* <div className="text-information"><img src={IconInformation} /> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</div> */}
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="text-center">
                                            <button type="button" onClick={this.handleSubmit} className="btn btn-primary waves-effect waves-light" disabled={this.state.isSubmit} >
                                                {
                                                    this.state.isSubmit ?
                                                        (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                                        : ''
                                                }
                                         Recharge
                                        </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-5 summary">
                                <div className="summary-body">
                                    <h2>SUMMARY</h2>
                                    <div className="summary-body-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-normal">Plan</td>
                                                    <td className="font-weight-bold">{planType}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-normal">{this.state.noHours}</td>
                                                    <td className="font-weight-bold">{total}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-normal">Subtotal</td>
                                                    <td className="font-weight-bold">{subtotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="font-weight-normal">Coupon Code (Optional)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <div className="code-div">
                                                            <div className="input-item">
                                                                {/* <input value={this.state.applyCode} onChange={(e) => { this.setState({ applyCode: e.target.value.replace(/\D/, '') }) }} type="text" placeholder="Enter 6-digit Code" /> */}
                                                                <input value={this.state.applyCode} onChange={(e) => { this.setState({ applyCode: e.target.value, codeIsApplied: false }) }} type="text" placeholder="Enter 6-digit Code" />
                                                            </div>
                                                            <div className={`button-item ${total >= 0 ? 'active' : ''} `}>
                                                                <button onClick={this.applyCode.bind(this)} >
                                                                    {
                                                                        this.state.isApply ?
                                                                            (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                                                            : ''
                                                                    }
                                                                    Apply
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-normal">GST (10% inclusive)</td>
                                                    <td className="font-weight-bold">{gst}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-normal">DISCOUNT ({discount_percentage}%)</td>
                                                    <td className="font-weight-bold">{discount}</td>
                                                </tr>
                                                <tr >
                                                    <td colSpan="2">
                                                        <div className="border-tr"></div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="2">
                                                        <div className="total-savings">
                                                            <div className="font-weight-normal">Your total savings</div>
                                                            <div className="font-weight-bold">{totalSavings}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-normal total-amount">Total Amount</td>
                                                    <td className="font-weight-bold total-amount">{total}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>
                <AddCard
                    open={this.state.addCard}
                    hideModal={event => this.setState({ addCard: false })}
                    getList={this.newCard.bind(this)}
                    addCard={this.newCard.bind(this)}
                />
            </React.Fragment >
        )
    }
}

export default CreditBalance;