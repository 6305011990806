import React from 'react';
import { MDBDataTable } from "mdbreact";

const DataTable = React.memo(props => {
    return (
        <React.Fragment>
            <MDBDataTable
                className="MyTable"
                sortable={false}
                searching={false}
                responsive
                data={props.data}
                entriesOptions={props.entriesOptions}
                entries={props.entries}
                displayEntries={false}
                hover
                disableRetreatAfterSorting={true}
                paging={props.paging ?? true}
            />
        </React.Fragment>
    );
});

export default DataTable;