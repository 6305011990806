import React from 'react';
import DataTable from "../../components/DataTable";
import HtmlParser from 'react-html-parser';
import moment from 'moment';

const TableContainer = React.memo(props => {
     
    const convertToHourAndMinute = (time) => {
        var hms = time;   // your input string
        let n = parseFloat(hms).toFixed(2);    // get only the 2 decimal after .
        var a = n.toString().split('.'); // split it at the colons
        var m = Math.floor(((a[1]) * 60), 10) / 100;        //convert decimal to minutes
        var hDisplay = a[0] > 0 ? a[0] + (a[0] == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";

        return hDisplay + mDisplay
    }

    const dataTable = {
        columns: [
            {
                label: "TICKET NO.",
                field: "tickt_no",
                width: 150
            },
            {
                label: "TICKET NAME",
                field: "tickt_name",
                width: 150
            },
            {
                label: "NAME",
                field: "name",
                width: 150
            },
            {
                label: "TIME",
                field: "time",
                width: 150
            },
            {
                label: "PRICING METHOD",
                field: "pricing",
                width: 150
            },
            {
                label: "EXPECTED COMPLETION",
                field: "completion",
                width: 150
            },
            {
                label: "STATUS",
                field: "status",
                width: 150
            }

        ],
        rows: []
    };

    const dataTableCurrent = {
        columns: [
            {
                label: "TICKET NO.",
                field: "tickt_no",
                width: 150
            },
            {
                label: "TICKET NAME",
                field: "tickt_name",
                width: 150
            },
            {
                label: "NAME",
                field: "name",
                width: 150
            },
            {
                label: "TIME",
                field: "time",
                width: 150
            },
            {
                label: "PRICING METHOD",
                field: "pricing",
                width: 150
            },
            {
                label: "EXPECTED COMPLETION",
                field: "completion",
                width: 150
            },
            {
                label: "STATUS",
                field: "status",
                width: 150
            },
            {
                label: "",
                field: "action",
                width: 150
            }

        ],
        rows: []
    };


    const dataTableCompleted = {
        columns: [
            {
                label: "TICKET NO.",
                field: "tickt_no",
                width: 150
            },
            {
                label: "TICKET NAME",
                field: "tickt_name",
                width: 150
            },
            {
                label: "NAME",
                field: "name",
                width: 150
            },
            {
                label: "TIME",
                field: "time",
                width: 150
            },
            {
                label: "PRICING METHOD",
                field: "pricing",
                width: 150
            },
            {
                label: "EXPECTED COMPLETION",
                field: "completion",
                width: 150
            }

        ],
        rows: []
    };
    
    let list = props.list;
    let completedTask = 0;
    let currentTask = 0;
    
    for (let index = 0; index < list.length; index++) {
        let ticket = list[index];
        let status = 'no-status';
        let action = {
            title: 'View Details',
            isApproval: false,
        };
        let time = ticket.pricing_method ? (ticket.pricing_method.name == 'Estimate' ? ticket.actual_time_used : ticket.estimated_time) : ticket.estimated_time
        if(ticket.status) {
            if(ticket.status.id === 1) {
                status='status-new';
            } else if(ticket.status.id === 2) {
                status='status-proposal';
                action.title = '✓ Approve Job Scope'
                action.isApproval = true;
            } else if(ticket.status.id === 3) {
                status='status-awaiting';
            } else if(ticket.status.id === 4) {
                status='status-inprogress';
            } else if(ticket.status.id === 5) {
                status='status-review';
            } else if(ticket.status.id === 6) {
                status='status-onhold';
            } else if(ticket.status.id === 7) {
                status='status-completed';
                action.title = '✓ Approve Go Live'
                action.isApproval = true;
            } else if(ticket.status.id === 8) {
                status='status-archive';
            } else if(ticket.status.id === 19) {
                status='status-archive';
                action.title = '✓ Approve Completion';
                action.isApproval = true;
            }
            //7 and 8
            if(ticket.status.id === 6 || ticket.status.id === 8) {
                completedTask++;
                dataTableCompleted.rows.push(
                    {
                        tickt_no: ticket.task_number ? ticket.task_number : '',
                        tickt_name: ticket.text,
                        name: ticket.requestor ? ticket.requestor.firstname + ' ' + ticket.requestor.surname : 'No Requestor',
                        time: time == '0.000000' ? '' : convertToHourAndMinute(time),
                        pricing: ticket.pricing_method ? ticket.pricing_method.name : '',
                        completion: ticket.due_date ? moment(ticket.due_date).format('DD-MM-YY, h:mm A') : '',
                        clickEvent: () => props.ticketDetails(ticket)
                    }
                );
            } else { 
               currentTask++;
                dataTableCurrent.rows.push(
                    {
                        tickt_no: ticket.task_number ? ticket.task_number : '',
                        tickt_name: ticket.text,
                        name:  ticket.requestor ? ticket.requestor.firstname + ' ' + ticket.requestor.surname : 'No Requestor',
                        time: time == '0.000000' ? '' : convertToHourAndMinute(time),
                        pricing: ticket.pricing_method ? ticket.pricing_method.name : '',
                        completion: ticket.due_date ? moment(ticket.due_date).format('DD-MM-YY, h:mm A') : '',
                        status: <span className={"status " + status}>{ticket.status ? ticket.status.status_title : 'NO STATUS'}</span>,
                        action: <span className={action.isApproval?"action":"view"} onClick={() => props.ticketDetails(ticket)}>{action.title}</span>,
                    }
                );
            }
        }

        dataTable.rows.push(
            {
                tickt_no: ticket.task_number ? ticket.task_number : '',
                tickt_name: ticket.text,
                name: ticket.requestor ? ticket.requestor.firstname + ' ' + ticket.requestor.surname : 'No Requestor',
                time: time == '0.000000' ? '' : convertToHourAndMinute(time),
                pricing: ticket.pricing_method ? ticket.pricing_method.name : '',
                completion: ticket.due_date,
                status: HtmlParser('<span class="status '+ status +'">' + (ticket.status == null ? 'NO STATUS' : ticket.status.status_title) + '</span>'),
                clickEvent: () => props.ticketDetails(ticket)
            }
        );
    }
    
    let type = props.type;
    let letTableData = dataTable;

    if(type === 1){
        letTableData = dataTableCurrent;
    }else if(type === 2){
        letTableData = dataTableCompleted;
    }
    
    return (
        <React.Fragment>
           <DataTable data={letTableData} />
        </React.Fragment>
    );
});

export default TableContainer;