import React, { Fragment } from 'react';
import Avatar from 'react-avatar';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getCookie,
    getFirstWord
} from '../../../Utils/helper';

let profileDetails = [];

if(getCookie('userDetails')){
    profileDetails = JSON.parse(getCookie('userDetails'));
}

const ProfileMenu = () => {
    const fname = profileDetails?.firstname || '';
    const lname = profileDetails?.surname || '';

    return (
        <Fragment>
            <div className="avatar-div">
                <Avatar 
                    color={"#556ee6"} 
                    name={`${fname ? getFirstWord(fname) : ''} ${lname ? getFirstWord(lname) : ''}`}
                    round={true}
                    size={50}
                />
            </div>
        </Fragment>
  );
}

const mapStatetoProps = state => {
    const { error,success } = state.Profile;
    return { error,success };
}

export default withRouter(connect(mapStatetoProps, { })(withNamespaces()(ProfileMenu)));

