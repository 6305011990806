import React,{useEffect} from 'react';
import { Alert} from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import logo from "../../assets/images/loop-logo.png";
import bgImage from "../../assets/images/login-bg.png";
import iconMail from "../../assets/images/icon-mail.png";
import iconCall from "../../assets/images/icon-call.png";
import TextInput from "../../components/TextInput";
import {
    UniversalValidator,
    setCookie
} from '../../Utils/helper';
import Api from "../../Utils/API";

function Login(props) {
    const params = queryString.parse(props.location.search);  
    const [state, setState] = React.useState({
        next: params.next,
        username: '',
        usernamError: '',
        password: '',
        passwordError: '',
        passwordType: 'password',
        passwordIcon: "fa fa-eye",
        errorLogin: false,
        isSubmit: false,
        rememberIsChecked: false
    });

    useEffect(() => {
        if(params.token) {
            if(state.accessToken) {
                setCookie(["access_token", state.accessToken]);
                setState({...state, isSubmit: true});
            }
    
            Api.loginAsContact(params.token).then(result =>{
                setCookie(["access_token", result.data.access_token]);
                setCookie(["projects", JSON.stringify(result.data.projects)]);
                setCookie(["userDetails", JSON.stringify(result.data.user)]);
                setCookie(["company", result.data.user.company.name]);
                setCookie(["settings", result.data.user.settings]);
    
                setTimeout( ()=>{
                    if(state.next){
                        window.location.href = state.next;
                    }else{
                        window.location.href = "/ticket";
                    }
                },1000);
            }).catch(error =>{
                setState({ ...state, errorLogin: true, isSubmit: false });
            });
        }
    }, []);

    function handleSubmit(event) {
        event.preventDefault();
        const usernameError = UniversalValidator({ value: state.username, isValidate: true, isUrl: false, errorMsg: 'Please enter username.' });
        const passworError = UniversalValidator({ value: state.password, isValidate: true, isUrl: false, errorMsg: 'Please enter password.' });
        if (usernameError || passworError) {
            setState({ ...state, usernamError: usernameError, passwordError: passworError });
        }else{
            setState({ ...state, isSubmit: !state.isSubmit });
            let params = {
                username: state.username,
                password: state.password,
                rememberIsChecked: state.rememberIsChecked
            }
            Api.login(params).then(result =>{
                let days = result.data.remember == 1 ? 2 : 1;
                setCookie(["access_token", result.data.access_token, days]);
                setCookie(["userDetails", JSON.stringify(result.data.user), days]);
                setCookie(["projects", JSON.stringify(result.data.projects), days]);
                setCookie(["company", result.data.user.company.name, days]);
                setCookie(["settings", result.data.user.settings]);
                setTimeout( ()=>{
                    if(state.next){
                        window.location.href = state.next;
                    }else{
                        // check props.prev
                        if(props.prev.includes('/login')) {
                            window.location.href = '/ticket';
                        } else {
                            window.location.href = props.prev;
                        }
                    }
                },1000);
            }).catch(error =>{
                setState({ ...state, errorLogin: true, isSubmit: false });
            });
        }
    }

    const tooglePassword = () => {
        if (state.passwordType === 'password') {
            setState({ ...state, passwordType: 'text', passwordIcon: "fa fa-eye-slash" });
        } else {
            setState({ ...state, passwordType: 'password', passwordIcon: "fa fa-eye" });
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid container-login">
                <div className="row row-login" >
                    <div className="col-md-6 login-left ">
                        <div className="form-login" >
                            {params.token ?
                                <div className="row loader-container">
                                    <span className="loader-text">Signing in</span>
                                    <div className="loader-boxes">
                                        <div className="📦"></div>
                                        <div className="📦"></div>
                                        <div className="📦"></div>
                                        <div className="📦"></div>
                                        <div className="📦"></div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="logo-div">
                                        <Link to="/login" className="back-home">
                                            <img src={logo} />
                                        </Link>
                                    </div>
                                    <div className="login-text">Log In</div>
                                    {
                                        state.errorLogin?
                                        (
                                            state.accessToken ?
                                            <Alert color="danger" role="alert">
                                                Invalid token.
                                            </Alert>
                                            :
                                            <Alert color="danger" role="alert">
                                                Incorrect username or password.
                                            </Alert>
                                        )
                                        :''
                                    }
                                    <form onSubmit={handleSubmit}>
                                        <TextInput
                                            name="username"
                                            type="text"
                                            label="Username"
                                            placeholder="Enter Username"
                                            error={state.usernamError}
                                            value={state.username}
                                            handleChange={event => setState({ ...state, username: event.target.value, usernamError: '' })}
                                        />
                                        <TextInput
                                            name="password"
                                            type={state.passwordType}
                                            label="Password"
                                            placeholder="Enter password"
                                            error={state.passwordError}
                                            value={state.password}
                                            handleChange={event => setState({ ...state, password: event.target.value, passwordError: '' })}
                                            icon={state.passwordIcon}
                                            tooglePassword={tooglePassword}
                                            isPassword={true}
                                        />
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                            <label className="custom-control-label checkbox-text" htmlFor="customControlInline">Remember me</label>
                                            <Link to="/forgot-password" className="forgot-password">
                                                Forgot your password?</Link>
                                        </div>
                                        <div className="mt-3">
                                        <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light btn-login" type="submit">
                                                <span>Sign In </span>
                                                {
                                                    state.isSubmit?
                                                    (<i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i>)
                                                    :''
                                                }
                                            </button>
                                        </div>
                                        <div className="mt-4 text-center back-home-container">
                                            <i className="fas fa-angle-left">
                                            <a target="_self" rel="noopener noreferrer" href="https://loopwebdesign.com.au/" className="back-home">Back to Home</a>
                                            </i>
                                        </div>
                                        <div className="text-center footer-login ">
                                            <div>
                                                <img className="icon-footer" src={iconMail} /> support@loopwebdesign.com.au
                                            </div>
                                            <div>
                                                <img className="icon-footer-call" src={iconCall} /> 1300 247 516
                                            </div>
                                        </div>
                                    </form>
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-md-6 login-right d-none d-md-block">
                        <div className="bg-image">
                            <img src={bgImage} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default withRouter(Login);